import React from "react"
import { RightArrow } from "../components/SVGIIcon"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"

const SecondPage = () => (
  <Layout>
    <PageHeader title="Careers" displayTitle="We are Hiring!" subtitle="Grab the opportunity to be a part of our fantastic team." />
    <section className="mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Open positions 1</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="careers mt-3">
              <h4>Engineering</h4>
              {job_eng_data.map((o, i) => (
                <Job {...o} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage

const Job = ({ name, location, link = "#" }) => (
  <a rel="noopener noreferrer" href={link} target="_blank">
    <div class="job_name">
      {name}
      <span>
        <RightArrow />
      </span>
    </div>
    <div class="job_location">{location}</div>
  </a>
)

const job_eng_data = [
  {
    name: "DevOps Engineer",
    location: "Noida, India",
    link:
      "https://drive.google.com/file/d/1rCaY4d9zQyGpXpGT_HPElzORz5GXanC0/view",
  }
]
